import Vue from 'vue'
import admin from './plugins/admin'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueMask from 'v-mask'
import Vue2Editor from "vue2-editor"
import VueSweetalert2 from 'vue-sweetalert2'


Vue.config.productionTip = false
Vue.use(VueMask)
Vue.use(Vue2Editor)
Vue.use(VueSweetalert2)
new Vue({
  i18n,
  admin,
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
