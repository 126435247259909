import Vue from "vue";
import service from "@/store/services/sync-order-log-service";

const state = {
  isLoadingData: false,
  list: [],
  meta: {},
};

const mutations = {
  SET_DATA: (state, payload) => {
    state.list = payload;
  },

  SET_META(state, data) {
    if (data.logs) {
      console.log(data.logs.current_page);
      state.meta.page = data.logs.current_page;
      state.meta.itemsPerPage = data.logs.per_page;
      state.meta.lastPage = data.logs.last_page;
      state.meta.totalItems = data.logs.total;
    }
  },

  CLEAN_LIST(state) {
    Vue.set(state, "list", []);
  },
};

const actions = {
  list: (store, option) => {
    store.state.isLoadingData = true;
    return service
      .list(option)
      .then((payload) => {
        store.commit("SET_DATA", payload.logs.data);
        store.commit("SET_META", payload);
        store.state.isLoadingData = false;
      })
      .catch((error) => {
        store.state.isLoadingData = false;
        throw error;
      });
  },

  downloadCSV: (store, option) => {
    return service.downloadCSV(option);
  },
};

const getters = {
  isLoadingData(state) {
    return state.isLoadingData;
  },

  list(state) {
    return state.list;
  },
  meta(state) {
    return state.meta;
  },
};

const syncOrderLogs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default syncOrderLogs;
